import {
  Controller
} from "@hotwired/stimulus";

// アコーディオンの挙動を制御する
export default class extends Controller {
  toggleOpen() {
    const buttons = document.querySelectorAll('.accordion-button');
    const collapsibles = document.querySelectorAll('.accordion-collapse');

    const button = document.getElementById("button-accordion-status");
    const icon = button.querySelector('i'); // アイコン要素を取得
    const buttonText = button.querySelector('span'); // ボタンのテキスト要素を取得

    const isAllCollapsed = Array.from(buttons).every(button => button.classList.contains('collapsed'));

    buttons.forEach((button, index) => {
      if (isAllCollapsed) {
        // 全て閉じている場合、全て開く
        button.classList.remove('collapsed');
        collapsibles[index].classList.add('show'); // コンテンツを表示
      } else {
        // 全て開いている場合、全て閉じる
        button.classList.add('collapsed');
        collapsibles[index].classList.remove('show'); // コンテンツを非表示
      }
    });
    // チェックボックスのラベルとアイコンを切り替え
    if (isAllCollapsed) {
      buttonText.textContent = 'すべて閉じる'; // テキストを更新
      icon.classList.remove('bi-caret-up-fill'); // 開くアイコンを削除
      icon.classList.add('bi-caret-down-fill'); // 閉じるアイコンを追加
    } else {
      buttonText.textContent = 'すべて開く'; // テキストを更新
      icon.classList.remove('bi-caret-down-fill'); // 閉じるアイコンを削除
      icon.classList.add('bi-caret-up-fill'); // 開くアイコンを追加
    }
  }
}